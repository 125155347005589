  /* Container that holds the Game of Life grid, fixed to act as background */
  .game-of-life-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;
  }

  /* Update grid positioning */
  .grid {
    display: grid;
    position: absolute;
    top: -20vh; /* Offset the grid vertically to ensure it covers the top */
    left: -20vw; /* Offset the grid horizontally to cover the left side */
    transform-origin: center; /* Rotate around the center to keep it balanced */
    transform: rotate(5deg); /* Keep the rotation with the added bias */
  }


  /* Individual cell styling */
  .cell {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.06); /* Light border */
  }
  
  /* Styling for live cells (black) */
  .cell-alive {
    background-color: rgba(0, 0, 0, 0.075); /* Semi-transparent black */
  }
  
  /* Styling for dead cells (white) */
  .cell-dead {
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  }
  